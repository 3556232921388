<template>
  <b-modal id="booking_modal" size="xl" title="Let's talk!" :hide-footer="true" @show="opened">
    <b-container fluid>
      <vue-calendly @load="loaded" url="https://calendly.com/cami-trotalo/site-budget" :height="600"></vue-calendly>
    </b-container>
  </b-modal>
</template>
<script>
export default {
  name: "BookConsultation",
  methods: {
    loaded(){
      this.$emit('loaded');
    },
    opened() {
      this.$emit('opened');
    }
  }
  
};
</script>
<style scope lang="scss">
//Place styles here
</style>