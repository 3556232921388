<template>
  <section id="plans" class="section section-lg pt-lg-0 mt--200">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-12">
                        <div class="row row-grid">
                          <swiper class="swiper" :options="swiperOption">
                            
                                <swiper-slide>
                                  <div>
                                    <card class="border-0" hover shadow body-classes="py-5">
                                        <icon name="ni ni-spaceship" type="primary" rounded class="mb-4">
                                        </icon>
                                        <h6 class="text-primary text-uppercase">Lean</h6>
                                        <p class="description mt-3">Build and host as many landing pages as you might need.
<br>
Using a powerful <a target="_blank" href="https://modx.com/">CMS</a>, manage, edit and publish your sites at a fraction of the traditional cost

</p>
                                        <div>
                                          <badge type="primary" rounded>Unlimited sites</badge>
<badge type="primary" rounded>Templates gallery</badge>
<badge type="primary" rounded>Netlify deployment</badge>
                                            <!--<badge type="primary" rounded>design</badge>
                                            <badge type="primary" rounded>system</badge>
                                            <badge type="primary" rounded>creative</badge>-->
                                        </div>
                                        <base-button tag="a" v-b-modal.booking_modal type="primary" class="mt-4">
                                            From 20USD/Month
                                        </base-button>
                                    </card>
                                </div>
                                </swiper-slide>    
                            
                                <swiper-slide>
                                  <div>
                                    <card class="border-0" hover shadow body-classes="py-5">
                                        <icon name="ni ni-briefcase-24" type="success" rounded class="mb-4">
                                        </icon>
                                        <h6 class="text-primary text-uppercase">Business</h6>
                                        <p class="description mt-3">Need to develop custom requirements for your business? 
<br>
Look no more, we have the simplest stack to get you on the right track!
</p>
                                        <div>
                                          <badge type="primary" rounded>Unlimited PWAs</badge>
<badge type="primary" rounded>User Management</badge>
<badge type="primary" rounded>Third party integrations</badge>
                                            <!--<badge type="primary" rounded>design</badge>
                                            <badge type="primary" rounded>system</badge>
                                            <badge type="primary" rounded>creative</badge>-->
                                        </div>
                                        <base-button tag="a" v-b-modal.booking_modal type="success" class="mt-4">
                                            From 60USD/Month
                                        </base-button>
                                    </card>
                                </div>
                                </swiper-slide>    
                            
                                <swiper-slide>
                                  <div>
                                    <card class="border-0" hover shadow body-classes="py-5">
                                        <icon name="ni ni-building" type="danger" rounded class="mb-4">
                                        </icon>
                                        <h6 class="text-primary text-uppercase">Corporate</h6>
                                        <p class="description mt-3">Shorten software production times while building complex applications by using the simplest, yet most perfomant web components along all the commonly needed features</p>
                                        <div>
                                          <badge type="primary" rounded>Native apps</badge>
<badge type="primary" rounded>Offline Mode</badge>
<badge type="primary" rounded>Business Process Engine</badge>
                                            <!--<badge type="primary" rounded>design</badge>
                                            <badge type="primary" rounded>system</badge>
                                            <badge type="primary" rounded>creative</badge>-->
                                        </div>
                                        <base-button tag="a" v-b-modal.booking_modal type="danger" class="mt-4">
                                            From 120USD/Month
                                        </base-button>
                                    </card>
                                </div>
                                </swiper-slide>    
                            
                            <div class="swiper-pagination" slot="pagination"></div>
                          </swiper>
                        </div>
                    </div>
                </div>
            </div>
        </section>
</template>
<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  name: "horizontalcards_273",
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
      return {
        swiperOption: {
          slidesPerView:  'auto',
          spaceBetween: 30,
          pagination: {
            el: '.swiper-pagination',
            clickable: true
          }
        }
      }
  },
  methods: {
    registerInterest(plan){
      debugger;
      if (window.$_Tawk && window.$_Tawk.downloaded && window.$_Tawk.addEvent instanceof Function) {
        window.$_Tawk.addEvent('interest-in-' + plan, function(error){
          console.error(error);
        });
      }
      
    }
  }
    
  
};
</script>
<style lang="scss" scoped>
  .swiper {
    height: 100%;
    width: 100%;
  
    .swiper-slide {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-weight: bold;
      /*font-size: $font-size-huge * 2;*/
      background-color: white;
      @media (max-width: 767.98px) { 
        width: 80%;
      }
      width: 30%;
      /*height: 80vh !important;*/
      height: 100% !important;
      /*@media (min-width: 768px) { 
        width: 30%;
      }*/
      
    }
  }

</style>