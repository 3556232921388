<template>
  <section id="contactUs" class="section section-lg py-4 section-contact-us">
      <div class="container">
          <div class="row justify-content-center">
              <div class="col-lg-8">
                  <card gradient="secondary" shadow body-classes="p-lg-5">
                      <h4 class="mb-1">Let's talk!</h4>
                      <p class="mt-0">Have doubts about your next steps? let us lead you on the right direction.</p>
                      <br>
                      <base-button @click="openChat" type="default" round block size="lg">
                          Send Message
                      </base-button>
                  </card>
              </div>
          </div>
      </div>
  </section>
</template>
<script>
export default {
  name: "contactus_282",
  methods: {
    openChat() {
      window.$_Tawk.toggle();
    }
  }
  
};
</script>
<style scope lang="scss">
//Place styles here
</style>