<template>
  <section id="twoColsImgRigth" class="section section-lg">
            <div class="container">
                <div class="row row-grid align-items-center">
                    <div class="col-md-6 order-md-2">
                        <img src="img/theme/vloxList.png" class="img-fluid floating">
                    </div>
                    <div class="col-md-6 order-md-1">
                        <div class="pr-md-5">
                            <icon name="ni ni-settings-gear-65" class="mb-5" size="lg" type="success" shadow
                                  rounded></icon>
                            <h3>How it works?</h3>
                            <p>We integrate the power of Vue and <a target="_blank" href="https://modx.com/">MODX</a> to bring you a tool where developers, content managers and designers can easily work together to deliver amazing digital experiences.</p>
<ul class="list-unstyled mt-5">
	<li class="py-2">
		<div class="d-flex align-items-center">
			<badge type="success" circle class="mr-3" icon="ni ni-cloud-download-95"></badge>
			<h6 class="mb-0">Bring your template, or ask for a personalized design</h6>
		</div>
	</li>
	<li class="py-2">
		<div class="d-flex align-items-center">
			<badge type="success" circle class="mr-3" icon="ni ni-settings-gear-65"></badge>
			<h6 class="mb-0">Set it up inside Vlox</h6>
		</div>
	</li>
	<li class="py-2">
		<div class="d-flex align-items-center">
			<badge type="success" circle class="mr-3" icon="ni ni-trophy"></badge>
			<h6 class="mb-0">Easily manage your content</h6>
		</div>
	</li>
	<li class="py-2">
		<div class="d-flex align-items-center">
			<badge type="success" circle class="mr-3" icon="ni ni-spaceship"></badge>
			<h6 class="mb-0">Host it on fast blazing servers</h6>
		</div>
	</li>
	<li class="py-2">
		<div class="d-flex align-items-center">
			<badge type="danger" circle class="mr-3" icon="ni ni-trophy"></badge>
			<h6 class="mb-0">Yearly plans includes 200 dev credits</h6>
		</div>
	</li>
</ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
</template>
<script>
export default {
  name: "twocolsimgrigth_274",
  
};
</script>
<style scope lang="scss">
//Place styles here
</style>