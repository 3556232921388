<template>
    <header class="header-global">
        <base-nav class="navbar-main" transparent type="" effect="light" expand>
            <div slot="brand" class="navbar-brand mr-lg-5" to="/">
                <img src="img/trotalo/logoMenu.svg" alt="logo">
            </div>

            <div class="row" slot="content-header" slot-scope="{closeMenu}">
                <div class="col-6 collapse-brand">
                    <a href="https://demos.creative-tim.com/vue-argon-design-system/documentation/">
                        <img src="img/trotalo/logoMenu.svg">
                    </a>
                </div>
                <div class="col-6 collapse-close">
                    <close-button @click="closeMenu"></close-button>
                </div>
            </div>

            <ul class="navbar-nav navbar-nav-hover align-items-lg-center">
              
                <base-dropdown class="nav-item" menu-classes="dropdown-menu-xl">
                  <a slot="title" href="#" class="nav-link" data-toggle="dropdown" role="button">
                      <i class="ni ni-ui-04 d-lg-none"></i>
                      <span class="nav-link-inner--text">Plans</span>
                  </a>
                  <div class="dropdown-menu-inner">
                    <a href="#plans"
 class="media d-flex align-items-center">
  <div class="icon icon-shape bg-gradient-warning rounded-circle text-white">
      <i class="ni ni-spaceship"></i>
  </div>
  <div class="media-body ml-3">
      <h6 class="heading text-primary mb-md-1">Lean</h6>
      <p class="description d-none d-md-inline-block mb-0">From landing pages to basic single page applications</p>
  </div>
</a><a href="#plans"
 class="media d-flex align-items-center">
  <div class="icon icon-shape bg-gradient-danger rounded-circle text-white">
      <i class="ni ni-briefcase-24"></i>
  </div>
  <div class="media-body ml-3">
      <h6 class="heading text-primary mb-md-1">Business</h6>
      <p class="description d-none d-md-inline-block mb-0">Manage complex security models, define business rules, and customize your applications with ease</p>
  </div>
</a><a href="#plans"
 class="media d-flex align-items-center">
  <div class="icon icon-shape bg-gradient-success rounded-circle text-white">
      <i class="ni ni-building"></i>
  </div>
  <div class="media-body ml-3">
      <h6 class="heading text-primary mb-md-1">Corporate</h6>
      <p class="description d-none d-md-inline-block mb-0">Integrate with third parties, deploy apps for offline use, and build anything you might imagine</p>
  </div>
</a>
                  </div>  
                </base-dropdown>
              
                
              
                <base-dropdown tag="li" class="nav-item">
                    <a slot="title" href="#" class="nav-link" data-toggle="dropdown" role="button">
                        <i class="ni ni-collection d-lg-none"></i>
                        <span class="nav-link-inner--text">About Us</span>
                    </a>
                    <a href="#twoColsImgRigth" class="dropdown-item">How it Works?</a><a href="#twoColsImgLeft" class="dropdown-item">How can we help?</a><a href="#gallery" class="dropdown-item">Our Projects</a><a href="#contactUs" class="dropdown-item">Contact Us</a>
                   
                </base-dropdown>
              
            </ul>
            <ul class="navbar-nav align-items-lg-center ml-lg-auto">
              
                  <li class="nav-item">
                    <a class="nav-link nav-link-icon" href="https://www.linkedin.com/company/trotalo" target="_blank" rel="noopener"
                       data-toggle="tooltip" title="Lets go!">
                        <i class="fa fa-brands fa-linkedin"></i>
                        <span class="nav-link-inner--text d-lg-none">Linkedin</span>
                    </a>
                </li>
                
                  <li class="nav-item">
                    <a class="nav-link nav-link-icon" href="https://www.instagram.com/trotalo/" target="_blank" rel="noopener"
                       data-toggle="tooltip" title="Lets go!">
                        <i class="fa fa-brands fa-instagram"></i>
                        <span class="nav-link-inner--text d-lg-none">Instagram</span>
                    </a>
                </li>
                
            </ul>
        </base-nav>
    </header>
</template>
<script>
import BaseNav from "@shared/components/BaseNav";
import BaseDropdown from "@shared/components/BaseDropdown";
import CloseButton from "@shared/components/CloseButton";

export default {
  name: "appheader_264",
    components: {
    BaseNav,
    CloseButton,
    BaseDropdown
  }
};
</script>
<style lang="scss">
/*.navbar-main{
  background-color: gray !important;
}*/
</style>