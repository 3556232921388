<template>
  <section id="gallery"  class="section section-shaped my-0 overflow-hidden">
    <div class="shape shape-style-3 bg-gradient-default shape-skew">
        <span></span>
        <!--<span></span>
        <span></span>
        <span></span>-->
    </div>
    <div class="container pt-lg pb-100">
        <div class="row text-center justify-content-center">
            <div class="col-lg-10">
                <h2 class="display-3 text-white">Our Projects</h2>
                <p class="lead text-white">Here are some of the cool stuff that we have build!</p>
            </div>
        </div>
    </div>
    <swiper class="swiper" :options="swiperOption">
      <swiper-slide>
  <div class="card bg-default shadow border-0">
    <img src="/img/trotalo/salomon.png" class="card-img-top">
    <blockquote class="card-blockquote">
        <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 583 95"
             class="svg-bg">
            <polygon points="0,52 583,95 0,95" class="fill-default"></polygon>
            <polygon points="0,42 583,95 683,0 0,95" opacity=".2" class="fill-default"></polygon>
        </svg>
        <h4 class="display-3 font-weight-bold text-white">Commercial Sites</h4>
        <p class="lead text-italic text-white">We'll help you set the building blocks for your to freely create<br><br>Check this example from a Californian client</p>
        <base-button tag="a"
         href="https://www.constructionandrepair.com/" target="blank"
         
         
         class="mb-3 mb-sm-0"
         type="info"
         icon="fa fa-solid fa-eye">
          View
        </base-button>
    </blockquote>
  </div>
</swiper-slide>  <swiper-slide>
  <div class="card bg-default shadow border-0">
    <img src="/img/trotalo/gallery/surveying1.png" class="card-img-top">
    <blockquote class="card-blockquote">
        <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 583 95"
             class="svg-bg">
            <polygon points="0,52 583,95 0,95" class="fill-default"></polygon>
            <polygon points="0,42 583,95 683,0 0,95" opacity=".2" class="fill-default"></polygon>
        </svg>
        <h4 class="display-3 font-weight-bold text-white">Surveying</h4>
        <p class="lead text-italic text-white">Manage any kind of surveying effort, from simple questionnaires, to multi user, offline mode apps, create, configure access, and collect any kind of information</p>
        <base-button tag="a"
         
         
          @click="setCurrentIndex(2)" v-b-modal.modal-1
         class="mb-3 mb-sm-0"
         type="info"
         icon="fa fa-solid fa-eye">
          View
        </base-button>
    </blockquote>
  </div>
</swiper-slide>  <swiper-slide>
  <div class="card bg-default shadow border-0">
    <img src="/img/trotalo/gallery/logistics1.png" class="card-img-top">
    <blockquote class="card-blockquote">
        <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 583 95"
             class="svg-bg">
            <polygon points="0,52 583,95 0,95" class="fill-default"></polygon>
            <polygon points="0,42 583,95 683,0 0,95" opacity=".2" class="fill-default"></polygon>
        </svg>
        <h4 class="display-3 font-weight-bold text-white">Logistics</h4>
        <p class="lead text-italic text-white">Define custom items and building types, add stop points and easily manage small to medium transport operations on an easy and affordable way</p>
        <base-button tag="a"
         
         
          @click="setCurrentIndex(3)" v-b-modal.modal-1
         class="mb-3 mb-sm-0"
         type="info"
         icon="fa fa-solid fa-eye">
          View
        </base-button>
    </blockquote>
  </div>
</swiper-slide>  <swiper-slide>
  <div class="card bg-default shadow border-0">
    <img src="/img/trotalo/gallery/vlox.png" class="card-img-top">
    <blockquote class="card-blockquote">
        <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 583 95"
             class="svg-bg">
            <polygon points="0,52 583,95 0,95" class="fill-default"></polygon>
            <polygon points="0,42 583,95 683,0 0,95" opacity=".2" class="fill-default"></polygon>
        </svg>
        <h4 class="display-3 font-weight-bold text-white">Vlox</h4>
        <p class="lead text-italic text-white">Custom integration between Vue and PHP though a highly flexible, performant and secure CMS, to enable any level of custom development and content management</p>
        <base-button tag="a"
         href="https://github.com/Trotalo/vlox" target="blank"
         
         
         class="mb-3 mb-sm-0"
         type="info"
         icon="fa fa-solid fa-eye">
          View
        </base-button>
    </blockquote>
  </div>
</swiper-slide>  <swiper-slide>
  <div class="card bg-default shadow border-0">
    <img src="/img/trotalo/gallery/Octocat.png" class="card-img-top">
    <blockquote class="card-blockquote">
        <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 583 95"
             class="svg-bg">
            <polygon points="0,52 583,95 0,95" class="fill-default"></polygon>
            <polygon points="0,42 583,95 683,0 0,95" opacity=".2" class="fill-default"></polygon>
        </svg>
        <h4 class="display-3 font-weight-bold text-white">Open source</h4>
        <p class="lead text-italic text-white">We believe in open source, here you can find all our current projects</p>
        <base-button tag="a"
         href="https://github.com/Trotalo" target="blank"
         
         
         class="mb-3 mb-sm-0"
         type="info"
         icon="fa fa-solid fa-eye">
          View
        </base-button>
    </blockquote>
  </div>
</swiper-slide>  
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
    <b-modal id="modal-1" size="xs" :title="currentProduct.title">
      <swiper class="swiper-internal" :options="swiperOptionInternal">
        <swiper-slide v-for="image in currentProduct.images" :key="image.source">
          <div class="card bg-default shadow border-0">
            <img :src="image.source" class="card-img-top">
            <blockquote class="card-blockquote">
                <!--<svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 583 95"
                     class="svg-bg">
                    <polygon points="0,52 583,95 0,95" class="fill-default"></polygon>
                    <polygon points="0,42 583,95 683,0 0,95" opacity=".2" class="fill-default"></polygon>
                </svg>-->
                <!--<h4 class="display-3 font-weight-bold text-white">{{image.text}}</h4>-->
                <p class="lead text-italic text-white">{{image.text}}</p>
                
            </blockquote>
          </div>
        </swiper-slide>  
      <div class="swiper-pagination2" slot="pagination"></div>
    </swiper>
    </b-modal>
  </section>
</template>
<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
  
export default {
  name: "gallery_279",
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
      return {
        currentProduct: {},
        swiperOption: {
          effect: "coverflow",
          grabCursor: true,
          centeredSlides: true,
          slidesPerView: "auto",
          slideToClickedSlide: true,
          coverflowEffect: {
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          },
          spaceBetween: 30,
          pagination: {
            el: '.swiper-pagination',
            clickable: true
          }
        },
        swiperOptionInternal: {
          grabCursor: true,
          centeredSlides: true,
          slidesPerView: "auto",
          slideToClickedSlide: true,
          autoplay: {
            delay: 2000,
            disableOnInteraction: false,
          },
          spaceBetween: 30,
          pagination: {
            el: '.swiper-pagination2',
            clickable: true
          }
        },
        productsInfo: [
        {
          id: 0,
          title: "Activity tracking",
          shortText:
              "Manage clients, assign teams, and track work progress",
          longText:
              "Do you have field workers whose activities change every day and you need to communicate and" +
              " track attendance along other data?",
          images: [
            {
              source: "images/timeTracking/branded-Login.png",
              text: "Your brand on your login page",
            },
            {
              source: "images/timeTracking/team-Managment.png",
              text: "Let your managers assign the workers to the clients",
            },
            {
              source: "images/timeTracking/worker-Activity-tasks.png",
              text: "Workers have their own dashboard showing the job assignment",
            },
            {
              source: "images/timeTracking/worker-Activity-register.png",
              text: "Let your workers register work times, location, or any other data you might need",
            },{
              source: "images/timeTracking/worker-Activity-record.png",
              text: "Easily see the recorder information",
            }
          ]
        },
        {
          id: 1,
          title: "Surveying",
          shortText:
              "From simple to elaborated forms, manage your surveyors and get results",
          longText:
              "Tired of all the complex and expensive surveying systems, and just needing something fast and easy" +
              " to get the job done? Look no further",
          images: [
            {
              source: "images/surveys/projects-dashboard.png",
              text: "Manage multiple projects and clients",
            },
            {
              source: "images/surveys/customizable-form.png",
              text: "Build any form that you might need",
            },
            {
              source: "images/surveys/project-form.png",
              text: "Customizable project form with team assignation",
            },
            {
              source: "images/surveys/client-access.png",
              text: "Let your clients see the progress in real-time",
            },
            {
              source: "images/surveys/notifications-and-messages.png",
              text: "Keep your team connected with in-app messages",
            },
            {
              source: "images/surveys/surveys-dashboard.png",
              text: "View the status of your projects and the people working on them",
            },
          ]
        },
        {
          id: 2,
          title: "Logistics",
          shortText:
              "Fully customizable logistics planning component with map integration and item management",
          longText:
              "Logistics systems can be complex and hard to use; get the job done with our logistics component",
          images: [
            {
              source: "images/logistics/customizable-logistics.png",
              text: "Configure logistic types, options, and even the number of default items.",
            },
            {
              source: "images/logistics/logistics-type.png",
              text: "Pick the type of logistics you'll be doing",
            },
            {
              source: "images/logistics/start-and-end-route.png",
              text: "Start by defining where your journey starts and where it ends",
            },
            {
              source: "images/logistics/smart-Address-Search.png",
              text: "Just start typing your address, and suggestions will popup",
            },
            {
              source: "images/logistics/add-Stops-And-Configure-Pickup.png",
              text: "Configure as many stops as you need, and add even custom items",
            },
          ]
        },
        {
          id: 3,
          title: "E-learning",
          shortText:
              "Collaboration with a design partner to build a fully integrated E-Learning platform",
          longText:
              "E-learning experiences can be hard to build, but by integrating the best tools for classes, " +
              "grading, content, and payment, we build a unique UX",
          images: [
            {
              source: "images/elearning/multiple-Courses.png",
              text: "Courses are loaded from a third-party service to provide a customized learning experience",
            },
            {
              source: "images/elearning/customizable-registration-form.png",
              text: "Get users enrolled in courses, and collect all the information that is relevant to you",
            },
            {
              source: "images/elearning/disscounts-And-Stripe-Integration.png",
              text: "Collect payments, and use discounts to attract new customers",
            },
            {
              source: "images/elearning/course-Content.png",
              text: "We integrate with LMSs like Google Classroom and provide a custom learning experience " +
                  "at the best cost",
            },
            {
              source: "images/elearning/admin-panel.png",
              text: "All parameters and content are completely configurable. We'll help you become self-sufficient",
            },
            {
              source: "images/elearning/emailCommunication.png",
              text: "From confirmation emails to campaigns, keep in touch with your customers all the time",
            },
          ]
        }
      ]
      }
    },
    methods: {
      setCurrentIndex(index) {
        this.currentProduct = this.productsInfo[index - 1];
        console.log(index - 1);
      }
    }
  
};
</script>
<style lang="scss" scoped>
  .swiper {
    height: 100%;
    width: 100%;
  
    .swiper-slide {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-weight: bold;
      /*font-size: $font-size-huge * 2;*/
      background-color: white;
      @media (max-width: 767.98px) { 
        width: 80%;
      }
      width: 30%;
      /*height: 80vh !important;*/
      height: 100% !important;
      /*@media (min-width: 768px) { 
        width: 30%;
      }*/
      
    }
    
  }
  .swiper-internal {
    height: 100%;
    width: 100%;
  
    .swiper-slide {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-weight: bold;
      /*font-size: $font-size-huge * 2;*/
      background-color: white;
      /*@media (max-width: 767.98px) { 
        width: 80%;
      }*/
      width: 95%;
      /*height: 80vh !important;*/
      height: 100% !important;
      /*@media (min-width: 768px) { 
        width: 30%;
      }*/
      
    }
    
  }
  a {
    color: white !important;
  }

</style>