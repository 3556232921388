<template>
  <!-- shape Hero -->
  <section class="section-shaped my-0">
      <div class="shape shape-style-1 shape-default shape-skew">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
      </div>
      <div class="container shape-container d-flex">
          <div class="col px-0">
              <div class="row">
                  <div class="col-lg-6">
                      <h1 class="display-3  text-white">The place for developers and agencies
                      </h1>
                      <p class="lead  text-white">From landing pages, to complex corporate applications, build, test and host web apps with ease</p>
                      <div class="btn-wrapper">
                        <base-button tag="a"
 
 
 v-b-modal.booking_modal
 class="mb-3 mb-sm-0"
 type="primary"
 icon="fa fa-solid fa-eye">
  Book a Demo
</base-button><base-button tag="a"
 href="https://github.com/trotalo" target="blank"
 
 
 class="mb-3 mb-sm-0"
 type="success"
 icon="fa fa-brands fa-github-alt">
  Open Source
</base-button>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </section>
  <!-- 1st Hero Variation -->
</template>
<script>
export default {
  name: "thehero_271"

};
</script>
<style scope lang="scss">
//Place styles here
</style>