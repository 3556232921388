<template>
  <section id=twoColsImgLeft class="section bg-secondary">
    <div class="container">
        <div class="row row-grid align-items-center">
            <div class="col-md-6">
                <div class="card bg-default shadow border-0">
                    <img src="img/trotalo/resEditor.png" class="card-img-top">
                    <blockquote class="card-blockquote">
                        <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 583 95"
                             class="svg-bg">
                            <polygon points="0,52 583,95 0,95" class="fill-default"></polygon>
                            <polygon points="0,42 583,95 683,0 0,95" opacity=".2" class="fill-default"></polygon>
                        </svg>
                        <h4 class="display-3 font-weight-bold text-white">We'll get you started</h4>
                        <p class="lead text-italic text-white">Migrating from other CMS, or custom development needs?, our team is ready to assist you.
</p>
                    </blockquote>
                </div>
            </div>
            <div class="col-md-6">
                <div class="pl-md-5">
                    <icon name="ni ni-settings" class="mb-5" size="lg" type="warning" shadow rounded></icon>

                    <h3>How can we help?</h3>
                    <p class="lead">From developer support and documentation, to affordable  development services.</p>
<p class="lead">With Vlox you'll enjoy the freedom of building things your way.</p>
<a v-b-modal.booking_modal
   class="font-weight-bold text-warning mt-5">Let's work together</a>
                </div>
            </div>
        </div>
    </div>
</section>
</template>
<script>
export default {
  name: "twocolsimgleft_275",
  
};
</script>
<style scope lang="scss">
//Place styles here
</style>